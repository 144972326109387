<template>
  <div class="dentalnext-loading">
    <img
      src="/assets/branding/logo.svg"
      alt="DentalNext"
      draggable="false"
      oncontextmenu="return false;"
    />
  </div>
</template>

<style lang="scss" scoped>
.dentalnext-loading {
  position: fixed;
  z-index: 999;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  line-height: 0;
  background-color: var(--white);
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    user-select: none;
    opacity: 0;
    animation: opacity 1.5s ease infinite;
    animation-delay: 1s;
    animation-direction: alternate;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
