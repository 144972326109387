<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

const userStore = useUserStore()
const clinicStore = useClinicStore()
const online = useOnline()
const showClinics = ref(true)
const isLoggedIn = computed(() => userStore.isLoggedIn)
const { height } = useWindowSize()
const { createWebsocket } = useWebsocketLogic()
useClinicSubscriptionToast()

useHead({
  meta: [
    {
      hid: 'theme-color',
      name: 'theme-color',
      content: userStore.app.themeColor,
    },
  ],
})

function logError(error: any) {
  console.error(error)
}

function checkAuthState() {
  if (isLoggedIn.value) {
    if (clinicStore.clinic) {
      createWebsocket()
      if (!clinicStore.staff.length) getStaff()

      showClinics.value = false
    } else {
      showClinics.value = true
    }
  }

  // To make sure token was loaded and denied
  if (isLoggedIn.value === false) {
    const router = useRouter()
    router.push({ path: '/logout/' })
  }
}

function setAppHeight() {
  document.documentElement.style.setProperty(
    `--app-height`,
    `${height.value}px`,
  )
}

watch(height, setAppHeight)

// Checking if the user is logged in. If it is, it checks if the user has a clinic. If it has, it gets
// the staff and the clinic data. If it doesn't, it shows the clinics. If the user is not logged in, it
// redirects to the logout page.
watch(
  isLoggedIn,
  (newVal) => {
    if (newVal === undefined || !online.value) return
    checkAuthState()
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  // Set app height to CSS, mainly to resolve vh problems
  setAppHeight()
})
</script>

<template>
  <BaseSplashscreen
    v-if="
      !isLoggedIn ||
      (isLoggedIn && clinicStore.clinic && !clinicStore.staff.length)
    "
  />
  <LazyUiClinicList
    v-else-if="showClinics"
    @updated-clinic="showClinics = $event"
  />
  <LazyFormsStaffWelcomeUser v-else-if="!userStore.auth?.user" />

  <div v-else>
    <UiTopMenu @updated-clinic="showClinics = $event" />
    <main class="app">
      <NuxtErrorBoundary @error="logError">
        <slot />
        <template #error="{ error, clearError }">
          <div
            class="flex min-h-screen flex-col items-center justify-center px-6 py-24 text-center sm:py-32 lg:px-8"
          >
            <h1
              class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
            >
              Ocorreu um erro
            </h1>

            <p class="mt-6 leading-7 text-gray-600">
              Caso o erro persista, contacte a equipa de suporte.
            </p>

            <i class="mt-2 leading-7 text-gray-600">{{ error }}</i>

            <UButton
              class="mt-6"
              label="Ir para a página inicial"
              @click="clearError"
            />
          </div>
        </template>
      </NuxtErrorBoundary>
    </main>
    <UiNavbar />
  </div>
</template>
