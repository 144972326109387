import type { WebsocketMessage } from '~/types/misc'
import { WebsocketEvent } from '~/types/misc'

export function useWebsocketLogic() {
  const clinicStore = useClinicStore()
  const userStore = useUserStore()

  let ws: WebSocket | null = null
  let heartbeatInterval: NodeJS.Timeout
  let attemptsToReconnectWs = 0
  const wsHadError = ref(false)
  const wsHadErrorToast = ref(false)

  function createWebsocket() {
    const config = useRuntimeConfig()
    const clinicId = localStorage.getItem('clinic')

    if (!clinicId) return

    ws = new WebSocket(
      `${config.public.wsEndpoint}/ws/status/?clinic_id=${clinicId}`,
    )

    ws.onopen = () => {
      clinicStore.setWebsocketConnection(true)

      // Send ping to server every minute to keep connection alive
      heartbeatInterval = setInterval(() => {
        ws?.send(JSON.stringify({ code: WebsocketEvent.HEARTBEAT }))
      }, 60000)

      // If there was an error, show success message
      if (wsHadError.value) {
        showToast('success', 'Sucesso', 'Conexão com o servidor restabelecida.')
        wsHadError.value = false
        wsHadErrorToast.value = false
      }
    }

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data as string) as WebsocketMessage

      if (data.code === WebsocketEvent.APPOINTMENT_CHANGED)
        return clinicStore.setLastAppointmentChange()

      if (data.code === WebsocketEvent.PATIENT_CHANGED)
        return clinicStore.setLastPatientChange()

      if (data.code === WebsocketEvent.STOCK_CHANGED)
        return clinicStore.setLastStockChange()

      if (data.code === WebsocketEvent.INVOICE_CHANGED)
        return clinicStore.setLastInvoiceChange()

      if (data.code === WebsocketEvent.NOTIFICATION_CHANGED)
        return clinicStore.setLastNotificationChange()

      if (data.code === WebsocketEvent.REMINDER_CHANGED)
        return clinicStore.setLastReminderChange()

      if (data.code === WebsocketEvent.CLINIC_CHANGED)
        return userStore.getUser()

      if (data.code === WebsocketEvent.STAFF_CHANGED) {
        userStore.getUser()
        return getStaff()
      }
    }

    ws.onclose = (e) => {
      retryWebsocket()
      console.error('Websocket error', e)
    }

    ws.onerror = (e) => {
      console.error('Websocket error', e)
    }
  }

  function retryWebsocket() {
    clearInterval(heartbeatInterval)
    ws?.close()
    ws = null
    clinicStore.resetWebsocketConnection()

    if (attemptsToReconnectWs >= 3) {
      wsHadError.value = true

      if (!wsHadErrorToast.value) {
        showToast('error', 'Erro', 'Erro na conexão com o servidor.')
        wsHadErrorToast.value = true
      }
    }

    setTimeout(() => {
      createWebsocket()
    }, attemptsToReconnectWs * 1000)

    attemptsToReconnectWs++
  }

  onBeforeUnmount(() => {
    // Close websocket
    if (ws) ws.close()
  })

  return {
    createWebsocket,
  }
}
