<script setup lang="ts">
const props = defineProps<{
  currentClinicName?: string
}>()

const emit = defineEmits(['updatedClinic'])

declare global {
  interface Window {
    Tawk_API: any
  }
}

const userStore = useUserStore()

const wasLiveChatAdded = ref(false)

function addLiveChatScriptToHead() {
  if (wasLiveChatAdded.value) return

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = `
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/65b65eee8d261e1b5f58d59f/1hl865l0q';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();`
  document.head.appendChild(script)
  wasLiveChatAdded.value = true

  // Maximize the chat window
  maximizeLiveChat()
}

function maximizeLiveChat() {
  if (!window.Tawk_API?.maximize) {
    setTimeout(() => {
      maximizeLiveChat()
    }, 1000)
  } else {
    window.Tawk_API.maximize()
  }
}

const items = ref([
  [
    {
      label: props.currentClinicName ?? '',
      slot: 'account',
      disabled: true,
    },
  ],
  [
    {
      label: 'Definições de Conta',
      icon: 'i-heroicons-cog-8-tooth',
      to: '/settings/account/',
    },
    // Clinic Settings is added conditionally down below
  ],
  [
    {
      label: 'Mudar de Clínica',
      icon: 'i-heroicons-arrow-path',
      click: () => {
        emit('updatedClinic', true)
      },
    },
  ],
  [
    {
      label: 'Suporte',
      icon: 'i-heroicons-chat-bubble-oval-left-ellipsis-solid',
      click: () => {
        addLiveChatScriptToHead()
      },
    },
  ],
  [
    {
      label: 'Sair',
      icon: 'i-heroicons-arrow-left-on-rectangle',
      to: '/logout/',
    },
  ],
])

if (userStore.isClinicAdmin) {
  items.value[1].push(
    // @ts-expect-error - not picking up the correct type from @nuxt/ui
    {
      label: 'Definições de Clínica',
      icon: 'i-heroicons-building-office-solid',
      to: '/settings/clinic/',
    },
  )
}
</script>

<template>
  <UDropdown
    class="items-center justify-center border-r border-solid border-slate-200 px-4"
    :items="items"
  >
    <UAvatar
      chip-color="green"
      size="md"
      :src="userStore.auth?.user?.profile_picture?.small ?? ''"
      :alt="`${userStore.auth?.user?.first_name} ${userStore.auth?.user?.last_name}`"
    />

    <div class="ml-3 mr-1 hidden flex-col justify-center lg:flex">
      <p class="max-w-24 truncate text-sm font-semibold text-gray-900">
        {{
          `${userStore.auth?.user?.first_name} ${userStore.auth?.user?.last_name}`
        }}
      </p>
      <p class="max-w-24 truncate text-xs">{{ currentClinicName }}</p>
    </div>

    <UButton
      icon="i-heroicons-chevron-down"
      size="xs"
      color="black"
      variant="ghost"
    />
  </UDropdown>
</template>
