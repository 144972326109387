<script setup lang="ts">
import { Permission } from '~/types/misc'

const userStore = useUserStore()
const route = useRoute()

const menuItems = ref(
  [
    { title: 'Dashboard', link: '/', icon: 'i-heroicons-home' },

    {
      title: 'Calendário',
      link: '/calendar/',
      icon: 'i-heroicons-calendar-days',
    },

    {
      title: 'Pacientes',
      link: '/patients/',
      icon: 'i-heroicons-users',
    },

    { title: 'Mensagens', link: '/chat/', icon: 'i-heroicons-inbox' },

    userStore.hasPermission(Permission.VIEW_INVOICE) && {
      title: 'Faturação',
      link: '/invoices/',
      icon: 'i-heroicons-banknotes',
    },

    userStore.hasPermission(Permission.VIEW_STOCK) && {
      title: 'Stock',
      link: '/stock/',
      icon: 'i-heroicons-squares-2x2',
    },

    userStore.isClinicAdmin && {
      title: 'Relatórios',
      link: '/reports/',
      icon: 'i-heroicons-chart-pie',
    },

    { title: 'Staff', link: '/staff/', icon: 'i-heroicons-user-group' },
  ].filter((item) => item !== false),
)
</script>

<template>
  <aside
    class="fixed inset-x-0 bottom-0 flex w-full items-center border-r-2 border-slate-200 bg-white text-sky-500 lg:top-0 lg:h-full lg:w-20 lg:flex-col"
  >
    <NuxtLink
      to="/"
      class="hidden h-20 w-full items-center border-b-2 border-slate-200 lg:flex lg:shrink-0"
    >
      <BaseLogo class="mx-auto size-8" />
    </NuxtLink>

    <ul
      class="flex w-full items-center space-x-2 overflow-x-auto px-2 sm:justify-center lg:my-2 lg:flex-col lg:justify-normal lg:space-x-0 lg:space-y-2 lg:overflow-y-auto lg:overflow-x-hidden"
    >
      <!-- Items Section -->
      <li v-for="item in menuItems" :key="item.title">
        <UTooltip :text="item.title">
          <NuxtLink
            :to="item.link"
            class="flex h-14 w-full items-center justify-center rounded-lg px-5 text-gray-800 transition-colors duration-300 hover:bg-sky-50 hover:text-sky-500"
            :class="{
              'bg-sky-100 text-sky-500':
                route.path.startsWith(item.link) && item.link !== '/',
            }"
          >
            <UIcon :name="item.icon" class="size-5" />
          </NuxtLink>
        </UTooltip>
      </li>
    </ul>
  </aside>
</template>

<style lang="scss">
aside {
  ul {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    li .router-link-exact-active {
      @apply bg-sky-100 text-sky-500;
    }
  }
}

@media screen and (max-width: 1023px) {
  aside {
    height: calc(4rem + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>
