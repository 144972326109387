import type { ClinicStatus } from '~/types/clinic'

export function useClinicSubscriptionToast() {
  const nuxtApp = useNuxtApp()
  const clinicStore = useClinicStore()
  const userStore = useUserStore()
  const router = useRouter()

  const expirationDate = computed(() => {
    if (clinicStore.clinic?.ended_at) return clinicStore.clinic?.ended_at
    if (clinicStore.clinic?.expires_at) return clinicStore.clinic?.expires_at
    return null
  })

  function showMessage(status: ClinicStatus) {
    switch (status) {
      case 'canceled':
        return `A sua subscrição foi cancelada e todos os dados da clínica serão apagados a partir de ${nuxtApp.$dayjs(expirationDate.value).add(1, 'month').format('DD/MM/YYYY')}, permanentemente. Se deseja reativar a sua subscrição ou se isto foi um erro, por favor, contacte o nosso suporte.`
      case 'unpaid':
        return `A sua subscrição está em atraso devido a falhas de pagamento. Por favor, regularize a sua situação para evitar a suspensão dos serviços e a eliminação dos dados.`
      case 'past_due':
        return `O pagamento da sua subscrição está em atraso. Por favor, atualize suas informações de pagamento para evitar a suspensão dos serviços e a eliminação dos dados.`
      case 'incomplete':
        return `O pagamento inicial da sua subscrição não foi concluído. Por favor, regularize a sua situação para ativar a sua subscrição e evitar a eliminação dos dados.`
      case 'incomplete_expired':
        return `A tentativa de pagamento inicial da sua subscrição expirou. Por favor, reinicie o processo de subscrição para evitar a eliminação dos dados.`
    }
  }

  watch(
    () => clinicStore.clinic?.status,
    (status) => {
      if (!userStore.isClinicAdmin) return

      if (status && status !== 'active') {
        showToast(
          'warning',
          'Subscrição',
          showMessage(status),
          0,
          userStore.isClinicOwner
            ? [
                {
                  label: 'Regularizar',
                  click: () =>
                    router.push('/settings/clinic/?tab=subscription'),
                },
              ]
            : [],
        )
      }
    },
    { immediate: true },
  )
}
