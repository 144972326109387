<script setup lang="ts">
defineEmits(['updatedClinic'])

const clinicStore = useClinicStore()

const isSearchLoaded = ref(false)
const isNotificationCenterOpened = ref(false)
const notificationWrapperRef = ref<HTMLElement>()
const numberOfNotifications = ref(0)

onClickOutside(notificationWrapperRef, () => {
  isNotificationCenterOpened.value = false
})

// When we close, it will refresh the notifications, but we can already put to 0, since we are marking them as read anyway
watch(
  () => isNotificationCenterOpened.value,
  (value) => {
    if (!value) {
      numberOfNotifications.value = 0
    }
  },
)
</script>

<template>
  <div
    class="static inset-x-0 top-0 z-10 border-b-2 border-b-slate-200 bg-white lg:fixed lg:ml-20"
  >
    <nav class="flex h-16 lg:h-[calc(5rem_-_2px)]">
      <div class="grow overflow-hidden">
        <div
          v-if="!isSearchLoaded"
          class="flex h-full items-center border-r border-r-slate-200 p-4 px-7 lg:px-8"
        >
          <svg
            class="aa-SubmitIcon fill-sky-500"
            viewBox="0 0 24 24"
            width="20"
            height="20"
          >
            <path
              d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"
            ></path>
          </svg>
          <input
            placeholder="Pesquise qualquer coisa..."
            class="ml-5 size-full focus:outline-none focus:ring-2 focus:ring-sky-500"
            @focus="isSearchLoaded = true"
            @blur="isSearchLoaded = true"
          />
        </div>
        <LazyUiSearch v-else />
      </div>
      <div class="flex items-center">
        <div
          ref="notificationWrapperRef"
          class="relative flex h-full items-center border-r border-solid border-slate-200 px-4"
        >
          <UChip
            :text="numberOfNotifications"
            size="xl"
            :color="numberOfNotifications ? 'orange' : 'primary'"
          >
            <UButton
              icon="i-heroicons-bell-20-solid"
              :color="numberOfNotifications ? 'orange' : 'primary'"
              class="flex size-10 justify-center"
              variant="soft"
              @click="isNotificationCenterOpened = !isNotificationCenterOpened"
            />
          </UChip>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <UiNotificationCenter
              v-show="isNotificationCenterOpened"
              :is-opened="isNotificationCenterOpened"
              @number-of-notifications="numberOfNotifications = $event"
            />
          </transition>
        </div>

        <UiTopNavbarProfile
          :current-clinic-name="clinicStore.clinic?.name"
          @updated-clinic="$emit('updatedClinic', true)"
        />
      </div>
    </nav>
  </div>
</template>
