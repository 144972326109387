<script setup lang="ts">
import type { Notification, NotificationType } from '~/types/notification'

const props = defineProps<{ isOpened: boolean }>()
const emit = defineEmits(['numberOfNotifications'])
const clinicStore = useClinicStore()
const nuxtApp = useNuxtApp()

const infiniteScrollRef = ref<HTMLElement>()
const pagination = ref({ count: 0, current: 0 })
const unreadNotifications = ref<number>(0)
const notifications = ref<Notification[]>([])
const firstLoad = ref<boolean>(false)
const isLoading = ref<boolean>(false)

async function refresh() {
  pagination.value.current = 0
  pagination.value.count = 0
  await getNotifications()
}

async function getNotifications(offset = pagination.value.current, limit = 5) {
  isLoading.value = true

  await nuxtApp.$api
    .getNotifications(offset, limit)
    .then((response) => {
      unreadNotifications.value = response.unread_count

      // Create a toast for each new notification, maximum of 5
      if (unreadNotifications.value > 0 && firstLoad.value && !props.isOpened) {
        const newNotifications = response.items
          .filter(
            (notification) =>
              !notifications.value.some(
                (oldNotification) => oldNotification.id === notification.id,
              ),
          )
          .filter((notification) => !notification.read)
          .slice(0, 5)

        for (const notification of newNotifications) {
          // Workaround - for some reason when we receive several notifications at once, not all toasts are shown
          setTimeout(() => {
            showToast(
              notification.type,
              getNotificationStyle(notification.type).title,
              notification.content,
            )
          }, 0)
        }
      }

      if (pagination.value.count) {
        notifications.value = [...notifications.value, ...response.items]
        pagination.value.current += limit
      } else {
        notifications.value = response.items
        pagination.value.count = response.count
        pagination.value.current += limit
      }
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
    .finally(() => {
      isLoading.value = false
    })

  // So we don't display toast on first load
  firstLoad.value = true
}

function getNotificationStyle(notificationType: NotificationType) {
  switch (notificationType) {
    case 'reminder':
      return {
        colorClass: 'text-amber-500',
        iconName: 'i-heroicons-bell-solid',
        title: 'Lembrete',
      }
    case 'info':
      return {
        colorClass: 'text-primary-500',
        iconName: 'i-heroicons-information-circle-20-solid',
        title: 'Informação',
      }
    case 'warning':
      return {
        colorClass: 'text-orange-500',
        iconName: 'i-heroicons-exclamation-triangle-solid',
        title: 'Aviso',
      }
    default:
      return {
        colorClass: 'text-gray-500',
        iconName: 'i-heroicons-question-mark-circle-20-solid',
        title: 'Notificação',
      }
  }
}

const { pause, resume } = useIntersectionObserver(
  infiniteScrollRef,
  ([{ isIntersecting }]) => {
    if (
      isIntersecting &&
      !isLoading.value &&
      pagination.value.count > pagination.value.current
    ) {
      isLoading.value = true
      getNotifications()
    }
  },
)

getNotifications()

watch(
  () => unreadNotifications.value,
  () => {
    emit('numberOfNotifications', unreadNotifications.value)
  },
  { immediate: true },
)

watch(
  () => props.isOpened,
  async () => {
    if (!props.isOpened && unreadNotifications.value > 0) {
      nuxtApp.$api.markNotificationAsRead()
    }

    // Pause or resume the infinite scroll
    if (props.isOpened) {
      resume()
    } else {
      pause()
    }
  },
)

watch(
  () => clinicStore.websocket.last_notification_change,
  () => {
    if (!isLoading.value) refresh()
  },
)
</script>

<template>
  <BaseCard
    title="Notificações"
    class="notification-center"
    :no-content-padding="true"
  >
    <template v-if="notifications.length" #headerIcon>
      <UButton
        icon="i-heroicons-check"
        variant="soft"
        class="ml-2"
        size="xs"
        @click="nuxtApp.$api.markNotificationAsRead(true)"
      />
    </template>

    <template #content>
      <div class="max-h-64 overflow-y-auto px-4 py-5 sm:p-6">
        <div v-if="isLoading && !notifications.length" class="space-y-4">
          <USkeleton v-for="i in 2" :key="i" class="h-[70px]" />
        </div>

        <BaseEmptyState
          v-else-if="!pagination?.count"
          icon="i-heroicons-bell-20-solid"
          icon-color="text-primary-500"
          title="Não existem novas notificações."
          description="Novas notificações irão aparecer aqui."
        />

        <div
          v-else
          class="divide-y [&>*:not(:first-child)]:pt-4 [&>*:not(:last-child)]:pb-0"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="mb-4 grid grid-cols-[25px_1fr] items-start"
          >
            <UIcon
              class="size-6"
              :class="getNotificationStyle(notification.type).colorClass"
              :name="getNotificationStyle(notification.type).iconName"
            />
            <div class="ml-2 grid gap-1">
              <p
                class="text-sm font-medium"
                :class="getNotificationStyle(notification.type).colorClass"
              >
                {{ getNotificationStyle(notification.type).title }}
                <span class="text-xs text-gray-500">
                  {{ nuxtApp.$dayjs(notification.created_at).fromNow() }}
                </span>

                <span
                  v-if="!notification.read"
                  class="ml-2 inline-block size-2 rounded-full bg-sky-500"
                ></span>
              </p>
              <p class="text-sm text-gray-500">
                {{ notification.content }}
              </p>
            </div>
          </div>

          <div
            v-if="isLoading && notifications.length"
            class="flex flex-col items-center justify-center pb-4"
          >
            <span
              class="i-heroicons-arrow-path-20-solid mx-auto mb-4 size-6 animate-spin text-sky-400 dark:text-sky-500"
              aria-hidden="true"
            />
            <p class="text-center text-sm text-gray-900 dark:text-white">
              A carregar...
            </p>
          </div>

          <div ref="infiniteScrollRef" class="!py-0" />
        </div>
      </div>
    </template>
  </BaseCard>
</template>

<style lang="scss">
.notification-center {
  @apply absolute right-0 top-full z-20 min-w-80 max-w-full;

  @media (max-width: 768px) {
    position: fixed;
    right: 0;
    left: 0;
    top: 4rem;
  }
}
</style>
